<script setup lang="ts">
import { computed, ref } from 'vue'
import { useTranslator } from 'sima-suite-translator-vue3'
import { useHost } from '@/composables'
import { useAppointmentStore } from '@/store'

const redirectTime = ref(5)
const { translate } = useTranslator()
const { brandsWeb } = useHost()
const appointmentStore = useAppointmentStore()
const url = computed(() => {
  const brand = appointmentStore.vehicle.brand!.code!.toString().toUpperCase()
  const kilometers = appointmentStore.vehicle.kilometers
  const plate = appointmentStore.vehicle.plate
  const model = appointmentStore.vehicle.model

  const domain = brandsWeb.value[brand]

  return `${domain}?&model=${model}&plate=${plate}&kilometers=${kilometers}&brand=${brand}`
})

const interval = setInterval(() => {
  redirectTime.value--
  if (redirectTime.value === 0) {
    clearInterval(interval)
    window.location.href = url.value
  }
}, 1000)
</script>

<template>
  <div class="flex h-full w-full flex-col items-center justify-center gap-2">
    <h2 class="h2-typography pb-spacing-s text-center font-semibold">
      {{ translate('AppointmentWizard.Steps.Step8.WrongBrand') }}
    </h2>
    <p class="b2-typography text-center">
      {{ translate('AppointmentWizard.Steps.Step8.Redirect.Message', [redirectTime.toString()]) }}
    </p>
    <p class="b2-typography text-center">
      {{ translate('AppointmentWizard.Steps.Step8.Redirect.AlternativeMessage') }}
      <a class="text-color-primary-500 hover:underline" :href="url">
        {{ translate('AppointmentWizard.Steps.Step8.Redirect.Message.Here') }} </a
      >.
    </p>
    <p class="b2-typography text-center">
      {{ translate('AppointmentWizard.Steps.Step8.Redirect.Appreciation.Message') }}
    </p>
  </div>
</template>
