<script setup lang="ts">
import {
  isMobile,
  SButton,
  SButtonSize,
  SButtonVariant,
  SDivider,
  SElevationTokens,
  SIcon,
  SList,
  SMainToolbar,
  SModal,
  SProgressCircular,
  SSheet
} from 'sima-suite-components-vue3'
import OcsBrandLogo from '@/components/atoms/OcsBrandLogo/OcsBrandLogo.vue'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onMounted, ref } from 'vue'
import { useHost } from '@/composables'
import { useAppointmentStore, useEditAppointmentStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import WorkshopMap from '@/components/organims/WorkshopMap/WorkshopMap.vue'
import {
  ROUTE_APPOINTMENT_CONFIRM,
  ROUTE_APPOINTMENT_FAILURE,
  ROUTE_MODIFY_APPOINTMENT
} from '@/router/routeNames.ts'
import dayjs from '@/utils/dayjs.ts'

const { translate, locale } = useTranslator()

const { brandLogo } = useHost()

const editAppointmentStore = useEditAppointmentStore()
const appointmentStore = useAppointmentStore()
const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const isModalVisible = ref(false)
const isCancelLoading = ref(false)

editAppointmentStore.appointmentId = route.query.appointmentId as string
editAppointmentStore.enterpriseId = route.query.enterpriseId as string

const isFailureErrorCodes = (statusCode: number) => {
  const errorCodes: Record<number, boolean> = {
    404: true,
    500: true
  }
  return errorCodes[statusCode]
}

onMounted(async () => {
  isLoading.value = true
  try {
    await editAppointmentStore.getAppointment()
  } catch (error: any) {
    const { status: statusCode } = error
    if (isFailureErrorCodes(statusCode)) {
      redirectToFailureView()
    }
  } finally {
    isLoading.value = false
  }
})

const redirectToFailureView = () => {
  appointmentStore.wasSentRequest = true
  router.push({
    name: ROUTE_APPOINTMENT_FAILURE,
    state: {
      title: translate('Error.CancelAppointment.Title'),
      description: translate('Error.CancelAppointment.Description')
    }
  })
}

const redirectToSuccessView = () => {
  appointmentStore.wasSentRequest = true
  router.push({
    name: ROUTE_APPOINTMENT_CONFIRM,
    state: {
      title: translate('Success.CancelAppointment.Title'),
      description: translate('Success.CancelAppointment.Description')
    }
  })
}

const logo = computed(() => {
  if (brandLogo.value) {
    return new URL(`../../assets/themes/logos/${brandLogo.value}`, import.meta.url).href
  }
  return ''
})

const phonesToText = (contact: any): string => {
  return [contact.mobile, contact.phone].filter(Boolean).join(' - ')
}

const details = computed(() => {
  return [
    {
      primaryText: editAppointmentStore.appointment.workshop.name!,
      secondaryText: editAppointmentStore.appointment.workshop.addressPart1!,
      showFullText: true,
      icon: 'mdi-map-marker-outline'
    },
    {
      primaryText: `${editAppointmentStore.appointment.vehicle.brand!} ${editAppointmentStore.appointment.vehicle.model!}`,
      secondaryText: editAppointmentStore.appointment.vehicle.plate,
      additionalText: `${editAppointmentStore.appointment.vehicle.kms} km`,
      icon: 'mdi-car-outline'
    },
    {
      primaryText: dayjs(editAppointmentStore.appointment.date).locale(locale.value).format('LL'),
      secondaryText: editAppointmentStore.appointment.time,
      showFullText: true,
      icon: 'mdi-calendar-outline'
    },
    {
      primaryText: editAppointmentStore.appointment.customer.name!,
      secondaryText: editAppointmentStore.appointment.customer.email!,
      additionalText: phonesToText(editAppointmentStore.appointment.customer),
      showFullText: true,
      icon: 'mdi-account-outline'
    }
  ]
})

const handleModify = () => {
  router.push({ name: ROUTE_MODIFY_APPOINTMENT })
}

const handleCancel = () => {
  isModalVisible.value = true
}

const handleCloseModal = () => {
  isModalVisible.value = false
}

const handleCancelAppointment = async () => {
  isCancelLoading.value = true
  try {
    await editAppointmentStore.cancelAppointment()
    isCancelLoading.value = false
    handleCloseModal()
    redirectToSuccessView()
  } catch (error) {
    isCancelLoading.value = false
    handleCloseModal()
    redirectToFailureView()
  }
}
</script>

<template>
  <s-sheet
    :elevation="SElevationTokens.null"
    class="w-full bg-color-white md:ml-auto md:mr-auto md:mt-spacing-xxxl md:h-fit md:w-3/4 md:shadow-shadow-m lg:w-3/5 lg:max-w-[1200px]"
  >
    <div
      class="flex flex-col items-center gap-0 px-spacing-xxl py-spacing-s sm:ml-spacing-s sm:flex-row md:h-28 md:justify-start md:gap-spacing-xs"
    >
      <ocs-brand-logo :src="logo" :alt="translate('AppointmentWizard.LogoAlt')" />
    </div>
    <s-divider />
    <div
      class="h-[calc(100vh_-_17rem)] px-spacing-m pt-spacing-s sm:h-[calc(100vh_-_18rem)] md:h-[calc(100vh_-_23rem)]"
    >
      <div
        v-if="!isLoading"
        class="flex h-full w-full overflow-y-hidden p-spacing-s pt-spacing-s sm:gap-spacing-m"
      >
        <div class="sm:w-4/6">
          <workshop-map
            :workshops="[editAppointmentStore.appointment.workshop]"
            :current-workshop="editAppointmentStore.appointment.workshop"
          />
        </div>
        <div class="w-full overflow-y-hidden sm:w-2/5">
          <s-main-toolbar
            class="pb-spacing-s"
            :title="translate('AppointmentWizard.Steps.Step7.Summary')"
          />
          <div class="h-full overflow-y-auto pb-spacing-xl">
            <s-list :items="details" show-full-text>
              <template #prependItem="detail">
                <div class="mr-spacing-xs">
                  <s-icon title="icon" :icon="detail.icon as unknown as string" />
                </div>
              </template>
            </s-list>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="flex h-full w-full items-center justify-center">
        <s-progress-circular />
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="fixed bottom-0 flex h-fit w-full justify-end gap-spacing-s p-spacing-s sm:relative"
    >
      <s-button
        :size="SButtonSize.big"
        :variant="SButtonVariant.text"
        flat
        :text="translate('EditAppointmentModal.CancelAppointment.Button')"
        @click="handleCancel"
      />
      <s-button
        :size="SButtonSize.big"
        flat
        :text="translate('AppointmentWizard.Modify')"
        @click="handleModify"
      />
    </div>
  </s-sheet>
  <s-modal
    v-model:is-visible="isModalVisible"
    :width="isMobile ? '100%' : '50%'"
    :content-height="isMobile ? '100%' : 'auto'"
    :title="translate('Modal.Appointment.Cancel.Title')"
    @modal-closed="handleCloseModal"
  >
    <template #content>
      <p class="b2-typography">
        {{ translate('Modal.Appointment.Cancel.Description') }}
      </p>
    </template>
    <template #actions>
      <div class="flex w-full items-center justify-end gap-2 py-spacing-xs">
        <s-button
          :size="SButtonSize.big"
          :variant="SButtonVariant.text"
          flat
          :text="translate('Modal.Appointment.Cancel.CancelButton')"
          @click="handleCloseModal"
        />
        <s-button
          :size="SButtonSize.big"
          flat
          :text="translate('Modal.Appointment.Cancel.AcceptButton')"
          :is-loading="isCancelLoading"
          @click="handleCancelAppointment"
        />
      </div>
    </template>
  </s-modal>
</template>
