import type { Settings } from './types'
import { localize as localizeVeeValidate, type RootI18nDictionary } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import es from '@vee-validate/i18n/dist/locale/es.json'
import pt from '@vee-validate/i18n/dist/locale/pt_PT.json'
import { configure as configureVeeValidate } from 'vee-validate'
import { setLocale } from '@vee-validate/i18n'
import { useTranslator } from 'sima-suite-translator-vue3'
const { language } = useTranslator()

const veeValidatei18nConfig: RootI18nDictionary = {} as const

export const configureVeeValidateLocales = () => {
  setLocale(language.value)
  localizeVeeValidate({ en, es, pt })
  configureVeeValidate({
    generateMessage: localizeVeeValidate(veeValidatei18nConfig)
  })
}

export const configureVeeValidateLocalesForTests = () => {
  configureVeeValidate({
    generateMessage: localizeVeeValidate({
      en
    })
  })
}

export async function loadConfiguration(): Promise<Settings> {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (import.meta.env.PROD) {
    const request = await fetch('./appSettings.json', { headers })
    // here goes the additional async calls you may need to do for additional settings
    return await request.json()
  }
  return configureDevelopmentSettings()
}

function configureDevelopmentSettings(settings?: Settings): Settings {
  const env = import.meta.env
  return {
    ...settings,
    backendUrl: env.VITE_BACKEND_URL || settings?.backendUrl,
    didomiId: env.VITE_DIDOMI_ID || settings?.didomiId,
    googleAnalyticsUA: env.VITE_GOOGLE_ANALYTICS_UA || settings?.googleAnalyticsUA,
    host: env.VITE_HOST || settings?.host,
    legalAdviserUrl: env.VITE_LEGAL_ADVISER_URL || settings?.legalAdviserUrl,
    mapsKey: env.VITE_MAPS_KEY || settings?.mapsKey,
    privacyPolicyUrl: env.VITE_PRIVACY_POLICY_URL || settings?.privacyPolicyUrl,
    title: env.VITE_TITLE || settings?.title,
    useFooter: Boolean(env.VITE_USE_FOOTER === 'true') || settings?.useFooter!,
    useLocalCookieBanner:
      Boolean(env.VITE_USE_LOCAL_COOKIE_BANNER === 'true') || settings?.useLocalCookieBanner!,
    useNewRGPDPolicy:
      Boolean(env.VITE_USE_NEW_RGPD_POLICY === 'true') || settings?.useNewRGPDPolicy!,
    webOwnerName: env.VITE_WEB_OWNER_NAME || settings?.webOwnerName,
    brandsWeb: settings?.brandsWeb! ?? {}
  }
}
